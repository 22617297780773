import React from "react"
import { Link } from "gatsby"

export default props => (

    <article

        className={`post-card ${props.count % 3 === 0 && `post-card-large`} ${
            props.postClass
        } ${props.node.frontmatter.thumbnail ? `with-image` : `no-image`}`}

        style={
            props.node.frontmatter.thumbnail && {
                backgroundImage: `url(${
                    props.node.frontmatter.thumbnail.childImageSharp.fluid.src
                })`,
            }
        }
    >

    { props.node.frontmatter.status === 'published' &&

        <Link to={`/${props.contentSlug}${props.node.fields.slug}`} className="post-card-link">

            <div className="post-card-content">

                <h2 className="post-card-title">
                    {props.node.frontmatter.title || props.node.fields.slug}
                </h2>
                
                <span className="text-center">
                    {/* TODO: melhorar a forma com que calcula o length  */}
                    {props.node.frontmatter.tags.map( (item, i) => {
                        if (props.node.frontmatter.tags.length === i + 1) {
                            return `${item}`
                        }
                        else{
                            return `${item}, `
                        }
                    }) || ''}
                </span>
            </div>

        </Link>
    }

    { props.node.frontmatter.status === 'soon' &&

        <div className="post-card-link">
                
            <div className="post-card-content">

                <h2 className="post-card-title">
                    {props.node.frontmatter.title || props.node.fields.slug}
                </h2>
                    
                <span>Em breve</span>

            </div>

        </div>
    }

  </article>
)   